.drag-zone {
    padding: 34px 40px 40px 40px;
    border: dashed 3px $primaryText;
    border-radius: 6px;
    text-align: center;

    &--accept, &:hover {
        cursor: pointer;
        border-color: $secondaryText;
    }

    &__icon-circle {
        margin: 0 auto 20px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.5em;
        height: 7.5em;
        border-radius: 50%;
        background: getLightBk($primary, 85%);
    }

    &__title {
        @extend .text--md;
        @extend .text--bold;
    }

    &__subtitle {
        color: $secondaryDark;
    }
}
