body, html {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.12px;
}

img {
    width: 100%;
    height: auto;
}

.appearance {
    animation: pulse .5s linear;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.logo-wrap {
    width: 192px;
}

.container .orders-list,
.container .clients-list {
    .list-item {
        margin: 10px 0;
        padding: 20px 32px 20px 18px;
        border-radius: 4px;

        .checkbox-label input + .checkbox-span {
            width: 28px;
            height: 28px;
        }

        .checkbox-label input + span::after {
            width: 28px;
            height: 28px;
        }
    }
}

.list-container {
    padding-top: 110px;
    padding-bottom: 118px;
    width: 100%;
}

.container {
    .list-item {
        &--checked {
            background-color: getLightBk($primary, 95%);
        }

        &__title {
            font-size: 14px;
            letter-spacing: -0.08px;
            line-height: 20px;
            color: $secondaryDark;
        }

        &__common {
            font-size: 20px;
            font-weight: 700;
            user-select: none;
        }
    }

    .sub-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 28px;
        right: 12px;
        text-align: right;
    }

    .sub-info {
        display: flex;
        padding-right: 0;
        padding-left: 0;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;

        &__title {
            color: $secondaryDark;
            letter-spacing: -0.08px;
            margin-right: 4px;
        }
    }

}

.detailed-headline {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.1px;
}