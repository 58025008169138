.orders-list {
    .row {
        margin-bottom: 16px;
    }

    .list-item__title {
        width: 160px;

        &-date {
            margin-right: 15px;
        }
    }

    .list-item__email {
        word-break: break-word;
    }
}

.orders-item {
    position: relative;

    &-id {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 12px;
        font-weight: 700;
    }

    &-title {
        font-size: 14px;
        color: $secondaryDark;
        letter-spacing: -0.08px;
    }

}
