// *** COLORS *** //
$white: #ffffff;
$black: #212121;
$white20: rgba($white, 20%);
$black12: mix(#000, #fff, 12%);
$black25: mix(#000, #fff, 25%);

$primary: #0072bc;
$primaryDark: #004978;
$primaryLight: #5ca5d4;

$secondary: #e5e5e5;
$secondaryDark: #7d7d7d;
$secondaryLight: #f0f0f0;

$accent: #0093f2;
$success: #00a237;
$attention: #eccb3a;
$warning: #ed254e;
$popupBack: #f5f5f5;

$primaryText: mix(#fff, #000, 87%);
$secondaryText: mix(#fff, #000, 54%);
$disabledText:  mix(#fff, #000, 62%);

$primary10: mix(#fff, $primary, 90%);

// *** TYPOGRAPHY *** //
$height: 100px;
$width: 1024px;

$font-normal: normal;
$font-bold: bold;