.loader-container {
    position: relative;
    width: 100%;
    &-md {
        height: 65vh;
    }
}

.loader {

    position: absolute;
	z-index: 10;
    border: 3px solid rgba(0, 0, 0, .5);
    border-top: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    &-sm {
        width: 28px;
        height: 28px;
        top: calc(50% - 14px);
        left: calc(50% - 14px);
    }
    &-md {
        width: 48px;
        height: 48px;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
    }
    &--white {
        border: 3px solid rgba(256, 256, 256, .5);
        border-top: 3px solid rgba(256, 256, 256, 0.1);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}