$border-size: 4px;
$border-color: #fff;
$font-size: 20px;

.impost {
    width: 3px;
    background-color: $disabledText;
}

.frame-wrap {
    width: 40%;
    height: 7em;
    border: 1px solid $disabledText;
}

.door-frame-wrap {
    width: 28%;
    height: 12em;

    .section {
        @include solidBorder($border-size + 4, $border-color);
    }

    &--double {
        width: 50%;
    }
}

.frame {
    height: 100%;
}

.window-frame {
    @include solidBorder($border-size, $border-color);
}

.section-group {
    color: $secondaryDark;
    font-size: $font-size;

    &--common {
        height: 100%; 
    }

    &--up {
        height: 30%; 
    }

    &--down {
        height: 70%; 
    }
}

.door-section-group {
    height: 100%;
    width: 100%;
}

@mixin backgroundImg($name){
    background-image: url(../../assets/img/constructions/#{$name}.png);
}

.section {
    $left_up: 'left_up';
    $right_up: 'right_up';
    $left: 'left';
    $right: 'right';
    $up: 'up';

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include solidBorder($border-size, $border-color);
    background-color: getLightBk($primary, 90%);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &--empty {
        background-color: $secondaryLight !important;
    }

    &--#{$left_up} {
        @include backgroundImg($left_up);
    }
 
    &--#{$right_up} {
        @include backgroundImg($right_up);
    }

    &--#{$left} {
        @include backgroundImg($left);
    }
    
    &--#{$right} {
        @include backgroundImg($right);
    }
    
    &--#{$up} {
        @include backgroundImg($up);
    }
}

.door-section {    
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: getLightBk($primary, 90%);
    @include solidBorder($border-size + 4, $border-color);

    &--up {
        height: 70%;
        background-color: getLightBk($primary, 90%);
    }

    &--down {
        height: 30%;
        background-color: #fff;
    }
}