.modal .map-modal {
    width: 90%;
    max-width: 1280px;

    .modal-header {
        padding: 18px 22px;
    }
}

.tooltip-container {
    line-height: 1.5;
    font-weight: 400;
    font-size: 14px;
    color: #212529;
}

.gm-ui-hover-effect {
    width: 38px !important;
    height: 38px !important;

    img {
        margin: -10px 10px !important;
        width: 22px !important;
        height: 22px !important;
    }
}