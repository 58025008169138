@mixin solidBorder($width, $color){
    border: $width solid $color;
}

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;
        src: url('#{$file-path}.woff') format('woff'),
        url('#{$file-path}.woff2') format('woff2'),
        url('#{$file-path}.ttf') format('truetype');
    }
}

@function getLightBk($color, $percent) {
    @return mix(#fff, $color, $percent);
}
