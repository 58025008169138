$caretMargin: 8px;

.ui-dropdown {
    width: calc(100% + #{$caretMargin});

    .dropdown-toggle {
        padding: .25rem 12px .25rem 12px;
        width: 110%;
        text-align: left;
        font-size: 14px;
        justify-content: flex-start;

        &--sm {
            height: 32px;
        }

        &:after {
           display: none;
        }
    }

    .dropdown-menu {
        font-size: 14px;
    }

    &__caret {
        position: absolute;
        right: $caretMargin;
        top: 10px;
        transform: rotate(90deg);
        z-index: 10;
    }

}

.ui-dropdown--blue {
    
    .dropdown-toggle {
        background-color: $primary10;
        color: $primary;
        border: none;
    }

    .ui-dropdown__caret {
        color: $primary;
    }
}
