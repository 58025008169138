.field-title, .field-value {
    line-height: 24px;
    letter-spacing: -.1px;
}

.field-title {
    font-size: 14px;
    color: $secondaryDark;
    text-transform: uppercase;

    span {
        color: #000;
    }
}

.field-value {
    font-size: 1.25rem;
}