.btn .icon-holder--right svg {
    margin-left: 8px;
}

.btn .icon-holder--left svg {
    margin-right: 8px;
}

.btn-tertiary {
    color: $primary;

    &--opacity10 {
        background-color: $primary10;
    }

    &--opacity20 {
        background-color: getLightBk($primary, 80%);
    }
}