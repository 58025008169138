.ui-row {
    margin-bottom: 10px;
}

.ui-field-title {
    padding-right: 8px;
    width: 112px;
}

.ui-field-value {
    padding-left: 8px;
    max-width: 160px;
    word-break: break-word;
    font-size: 16px;
}