.empty-list {
    margin: 122px 0;
    text-align: center;
    font-size: 20px;

    &__img {
        margin: 20px 0;
        text-align: center;
        width: 90px;
    }
}