.login-form-container {
    .logo-wrap {
        margin-bottom: 60px;
    }

    .form-control {
        margin-bottom: 2px; 
    }

    .btn {
        margin: 8px 0 12px 0; 
        width: 100%;
    }
}

.login-form-block {
    max-width: 466px;
    margin: 200px auto;
    padding: 100px 0;
    background-color: #fff; 
    border-radius: 4px;

    .input-container {
        margin-bottom: 10px; 
    }
}