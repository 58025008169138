$ligtWhite: #fbfbfb;
$grey: #dddddd;

.checkbox-label {
    margin-bottom: 0;
    cursor: pointer;
}

.checkbox-input--checked {
    float: left;
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
}

.checkbox-label::-moz-selection,
.checkbox-label::-moz-selection {
    background: transparent;
}

.checkbox-label::-moz-selection,
.checkbox-label::selection {
    background: transparent;
}

.checkbox-label input + .checkbox-span {
    @include solidBorder(1px, $grey);
    border-radius: 3px;
    background-color: $ligtWhite;
    content: "";
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    transform: translate3d(0, 0, 0);
}

.checkbox-label input + .checkbox-span--checked {
    outline: none;
    background-color: $primary;
    border: none;
}

.checkbox-label input + .checkbox-span:focus {
    outline: none;
}

.checkbox-label input + span::after {
    content: "";
    display: block;
    transform: scale(0);
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.checkbox-label .checkbox-input--checked + span::after {
    -ms-transform: scale(1);
    transform: scale(1);
}

.checkbox-label input {
    display: none;
    position: absolute;
}

.checkbox-label input + span {
    border-radius: 2px;
}

.checkbox-label input + span::after {
    margin: 0 auto;
    background: url(./../../assets/img/check.svg);
    background-repeat: no-repeat;
    background-size: 59%;
    background-position: center;
    width: 32px;
    height: 32px;
}

.checkbox-label input:checked + span::before {
    position: absolute;
    left: 6px;
    top: 6px;
    z-index: 1;
    width: 12px;
    height: 7px;
    border: 2px solid #000;
    border-top-style: none;
    border-right-style: none;
}

.checkbox-label input + .checkbox-span--checked-any::after {
    margin: 0 auto;
    background: url(./../../assets/img/multiple.svg);
    transform: scale(1);
    background-position: center;
    width: 14px;
}
