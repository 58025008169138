.nav {
    padding: 18px 32px !important;
    position: fixed;
    width: 240px;
    height: 100vh;
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-weight: 800;
	z-index: 1;
}

.nav-item {
    margin: 5px 0;

    a {
        color: $secondaryText;
        letter-spacing: 0.84px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;

        &.active {
            display: block;
            padding: 2px 14px;
            color: $primary;
            border-left: 2px solid $primary;
            opacity: 1;
        }
    }
}

.sub-nav-item {
    margin-left: 12px;
    a {
        font-size: .75em;
        letter-spacing: 0.5px;
        }
}