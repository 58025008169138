.notifications-tc {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2000;
}

.notification {
	padding: 12.5px 16px;
	background: $primaryDark;
	transition: all .5s ease-out;
	transform: translateY(-100%);
	border-radius: 3px;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.2px;
	color: #fff;
	&-success {
		background-color: getLightBk($success, 90%);
		@include solidBorder(1.5px, getLightBk($success, 70%));
		color: $success;
	}
	&-info {
		background-color: getLightBk($accent, 90%);
		@include solidBorder(1.5px, getLightBk($accent, 70%));
		color: $accent;
	}
	&-error {
		background-color: getLightBk($warning, 90%);
		@include solidBorder(1.5px, getLightBk($warning, 70%));
		color: $warning;
	}
	&-warning {
		background-color: getLightBk($attention, 90%);
		@include solidBorder(1.5px, getLightBk($attention, 70%));
		color: $attention;
	}
	&-hidden {
		transform: translateY(-100%);
	}
	&-visible {
		transform: translateY(0);
	}
}

.notification-content {
	display: flex;
}

.notification-icon {
	flex-grow: 0;
	margin: 0 12px 0 0;
	width: 16px;
	height: 16px;
}

.notification-message {
	flex-grow: 1;
}

.notification-dismiss {
	flex-grow: 0;
	cursor: pointer;
}

span.notification-dismiss {
	display: none;
}