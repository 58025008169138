.input-container {
    position: relative;
}

.input-container--icon-right input {
    padding-right: 28px;
}

.input-container--icon-left input {
    padding-left: 32px;
}

.input-container--icon-left .icon-wrap--left {
    left: 10px;
}

.input-container--icon-right .icon-wrap--right {
    right: 10px;
}

.icon-wrap {
    position: absolute;
    top: 8px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 16px;
    text-align: center;
    align-items: center;
}

.form-control.form-control--error {
    @include solidBorder(1px, $warning);
}

.error-message {
    font-size: 14px;
    color: $warning;
}