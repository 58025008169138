.modal-order .modal-body {
    padding: 38px 8px 32px 36px;
}

.modal-order {
    .button-group {
        width: 100%;
        .btn {
            width: 168px;
        }
    }
    .button-sub-group {
        .btn {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
}

.detailed-wrapper {
    >.detailed-headline {
        margin-bottom: 24px; 
    }
}

.person-info {

    &-wrapper {
        margin-bottom: 56px;
        .field-title {
            flex: 0 0 184px;
        }
    }

    &-container {
        margin-bottom: 22px;
    }

}

.windows-info-wrapper {
    padding-top: 28px;
    border-top: 2px solid $black12;
}

.position-item-container {    
    &:not(:last-child) {
        padding-bottom: 18px;
        margin-bottom: 36px;
        border-bottom: 1px solid $black12;
    }
    &:last-child {
        padding-bottom: 16px;
    }
}

.position-item__number {
    margin-right: 10px;
    width: 34px !important;
    height: 34px;
    background: $primary;
    border-radius: 28px;
    color: #fff;
    font-size: 22px;
    letter-spacing: -1.7px;
}

.configuration-container {
    margin: 0 10px;
    width: 44%;
}

.windows-params-list {
    margin-left: 10px;
}

.windows-params-list__name {
    font-weight: 700;
}

.position-item--primary {
    padding-bottom: 10px;
    margin-bottom: 10px;
}


.position-item--secondary {
    margin-bottom: 12px;

    > div:nth-child(2n) {
        margin-bottom: 22px;
    }
    
    > div:last-child() {
        margin-bottom: 0px;
    }

    .windows-params-list__name {
        margin-bottom: 8px;
    }

    .ui-row {
        margin-right: 8px; 
        width: 200px;
        
        .ui-field-title {
            width: 80px;
            font-size: 12px;
        }
        
        .ui-field-value {
            font-size: 14px;
        }
    }
}