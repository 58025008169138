$grey: rgb(231, 233, 234);
$shadow: rgba(0, 0, 0, 0.22);

.pagination-container {
	position: fixed;
	bottom: 0;
	z-index: 1000;
}

.pagination, .pagination>li>a, .pagination>li>span {
    display: flex;
	justify-content: center;
	z-index: 1000;
	background: #fff;
}

.pagination {
	margin: 0 0 55px;
	display: flex;
	justify-content: center;
	box-shadow: 0 9px 23px 0 $shadow;
	& > li > a, & > li > span {
		width: 48px;
		height: 48px;
		@include solidBorder(1px, $grey);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #868e96;
   }
	li.page--disabled {
		pointer-events: none;

		&:hover {
			cursor: default;
		}

		& > a {
			color: #e7e9ea;
	   }
   	}

   	& > li {
	   display: flex;
	}
	
	& > li {
		display: flex;
	}

	&>li:last-child>a, &>li:last-child>span {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
}


.page-caret--prev {
	transform: rotate(180deg);
}


.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
	background-color: #fff;
	color: #007bff;
	border-color: #e7e9ea;
}

.pagination>.disabled {
	background-color: #fff;
	background-color: red;
}