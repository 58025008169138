@import "vars";
@import "mixins";
@import "fonts";

@import './normalize.css';
@import "./override-bootstrap";

// ui //
@import "../../ui/Checkbox/style.scss";
@import "../../ui/Button/style.scss";
@import "../../ui/Field/style.scss";
@import "../../ui/CustomField/style.scss";
@import "../../ui/Popup/style.scss";
@import "../../ui/FileDropzone/style.scss";
@import "../../ui/Loader/style.scss";
@import "../../ui/Input/style.scss";
@import "../../ui/Dropdown/style.scss";
@import "../../ui/Pagination/style.scss";

@import "page";
@import "../../Layout/style.scss";
@import "../../Layout/Header/style.scss";
@import "../../Layout/NavBar/style.scss";

@import "../../app/Login/style.scss";
@import "../../app/Filter/style.scss";
@import "../../app/Orders/components/OrdersList/style.scss";
@import "../../app/Orders/components/Map/style.scss";
@import "../../app/Constructions/style.scss";
// @import "../../app/3dModels/components/VirtualModelsList/style.scss";
// @import "../../app/Clients/components/ClientsList/style.scss";
@import "../../app/Notifications/style.scss";
@import "../../app/EmptyLists/style.scss";

@import "../../app/Orders/components/DetailedOrder/style.scss";
@import "../../app/3dModels/components/NewModel/style.scss";

.flex {
  display: flex;
	&-col {
		flex-direction: column;
  }
	&--jc {
	&_flex-end {
		justify-content: flex-end;
	}
	&_center {
		justify-content: center;
	}
	&_space-between {
		justify-content: space-between;
	}
	&_space-around {
		justify-content: space-around;
	}
	}
	&--ai {
	&_flex-start {
		align-items: flex-start;
	}
	&_flex-end {
		align-items: flex-end;
	}
	&_center {
		align-items: center;
	}
	&_baseline {
		align-items: baseline;
	}
	&_stretch {
		align-items: stretch;
	}
	}
	&--direction {
	&_column {
		flex-direction: column;
	}
	}
	&--wrap {
	&_wrap {
		flex-wrap: wrap;
	}
	}
	&--grow_1 {
		flex-grow: 1;
	}
	&--grow_2 {
			flex-grow: 2;
	}
	&--shrink_0 {
			flex-shrink: 0;
	}
	&--shrink_2 {
			flex-shrink: 2;
	}
	&--overflow_hidden {
			overflow: hidden;
	}
}

.text {
	&--xxs {
		font-size: 12px;
	}
	&--xs {
		font-size: 14px;
	}
	&--sm {
		font-size: 16px;
	}
	&--md {
		font-size: 20px;
	}
	&--lg {
		font-size: 28px;
	}
	&--secondary {
		color: mix($secondaryDark, #fff, 50%);
	}
	&--bold {
		font-weight: 800;
	}
}
