@media only screen and (min-width: 980px) {
    .modal-open .header-container  {
        left: -15px;
    }
}

@media only screen and (max-width: 720px)  {
    .modal-open .header-container  {
        left: -15px;
    }
}

.modal-open .modal {
    display: flex !important;
    justify-content: center;
}

.modal-backdrop.show {
    opacity: .65 !important;
}

.modal {
    .modal-dialog {
        
        margin: 100px auto;
        width: 722px;
        max-width: 722px;
        min-height: 248px;
    }

    .modal-content {
        max-height: 100%;
        border-radius: 8px;
        background-color: $popupBack;
    }

    .modal-header {
        align-items: center;
        padding: 38px 22px;
        border-bottom: 2px solid $black12;
        flex-grow: 0;
        flex-shrink: 0;

        .modal-close {
            position: absolute;
            right: 23px;
            background: $primary10;
            opacity: 1;
            border-radius: 21px;
            width: 42px;
            height: 42px;
            cursor: pointer;

            &:hover .modal-close__icon {
                opacity: .5;
            }

            &__icon {
                position: absolute;
                top: 12px;
                left: 12px;
                font-size: 1.3em;
            }
        }
    }

    .modal-title {
        display: flex;
        align-items: center;
        font-size: 28px;
        letter-spacing: -0.2px;
        line-height: normal;
        color: $primary;
        font-weight: 700;
    }

    .modal-body {
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: auto;
        font-size: 16px;
    }

    .modal-footer {
        padding: 36px;
        justify-content: flex-start;
        flex-grow: 0;
        flex-shrink: 0;
        background: $primary10;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

}

@media (max-height: 960px){
    .modal {
        .modal-dialog {
            margin: 55px auto;
        }
    }
}

.modal.fade .modal-dialog.ui-modal-container {
    transform: translate(0, 15%) scale(.95);
}

.modal.show .modal-dialog.ui-modal-container {
	transform: translate(0, 0) scale(1);
}