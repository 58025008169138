.header-container {
    padding: 16px 32px;
    position: fixed;
	top: 0;
	right: 0;
    left: 0;
    height: $height;
    background: $primary10;
    z-index: 60;
}

.header {
    max-width: $width;
    margin: 0 auto;
}