//*** Bootstrap overrides ***//

.container, .modal {

    .row {
        margin-bottom: 11px;
    }

    .list-group {
        display: block;
    }

    .btn-xs {
        height: 32px;
    }

    .btn {
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .btn-md {
        padding: .375rem 1rem;
        height: 38px;
        font-size: 14px;
    }

    .btn-l {
        height: 40px;
        font-size: 14px;
    }

    .btn-sm-lg {
        height: 48px;
        font-size: 16px;
    }
    
    .btn-lg {
        height: 52px;
        font-size: 16px;
    }

    .btn-default {
        background-color: #fff;
	    border-color: $black25;
    }

    .btn-primary {
        background-color: $primary;
	    border-color: $primary;
    }

    .btn-outline-primary {
		border: 2px solid getLightBk($primary, 80%);
	}

    .btn-primary.disabled, .btn-primary:disabled {
        color: #fff;
        background-color: $black25;
        border-color: $black25;
    }

    .badge {
        padding: .4em .8em;

        &-secondary {
            color: $secondaryDark;
            background: $secondary;
        }
    }

    // Inputs
    .form-control-lg {
        font-size: 16px;
        letter-spacing: .3px;
    }
}