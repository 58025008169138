.filter {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 10px 25px 0 rgba(0,0,0,.15);

    &-container {
        margin-left: -10px;
        width: 735px;
        background-color: #fff;
        padding-top: 34px;
        position: fixed;
        z-index: 50;
    }
}

.group-action-button {
    position: relative;
    margin: 0 0 0 2px;
    width: 44px;

    &__icon {
        position: absolute;
        top: 8px;
        left: 16px;
    }
}

.download-icons-group {
    width: 100px;
    height: 40px;
    
    .download-icons {
        margin: 0 4px;
        width: 38px;
    }
}

.download-icons {
    position: relative;
    padding: 2px;
    cursor: pointer;
    transition: all .5s;

    &:hover {
        opacity: .8;
    }

    &:active, &:focus {
        width: 36px;
        opacity: .85;
        transform: translate(2px, -2px);
    }

    .loader-container {
        position: absolute;
        top: 50%;
        left: 0;
    }
}

.filter-dropdown-actions {
    width: 154px;
    margin: 0 20px 0 10px;
}

.filter-dropdown-options {
    width: 138px;
}

.input-search-container {
    margin-left: 8px;
}

.input-search {
    width: 268px;
}

.input-search__cross {
    cursor: pointer;
}

.date-picker-container {
    position: absolute;
    background-color: #fff;
    @include solidBorder(1px, #dddddd);
    border-radius: 2px;

    .reset-btn {
        margin: 8px auto;
    }
}

.DayPicker-Day {
    padding: 4px; 
}

.date-input-container {
    width: 148px;
}

.date-picker-input {
    text-align: center;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $primary;
}
  
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $primaryLight;
}